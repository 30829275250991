import { gql } from '@clientos/graphql-client';

const GET_SUBSCRIPTIONS = gql`
  query GetSubscriptionsDetails($subscriptionFilter: SubscriptionFilter) {
    account {
      subscriptions(subscriptionFilter: $subscriptionFilter) {
        resourceId
        friendlyId
        type
        shippingAddress {
          resourceId
          firstName
          lastName
          address
          address2
          addressType
          city
          state
          postalCode
          phoneNumber
        }
        devices {
          items {
            images {
              url
            }
            identity {
              friendlyName
              makeAndModel {
                name
              }
            }
          }
        }
        state
        price {
          price
          currency
        }
        billing {
          nextBillingCycle {
            chargeDate
            commonBillingDate
          }
          paymentMethod {
            status
            type
            ... on CreditCard {
              status
              type
              cardType
              lastFour
              expirationDate
            }
            ... on PayPal {
              status
              type
              email
            }
            ... on DirectDebit {
              status
              type
              accountName
            }
            ... on CitizensPay {
              status
              type
              offerCategory
            }
            ... on TwoCheckout {
              status
              type
            }
          }
        }
        entities {
          ... on InstantInkEntity {
            resourceId
            type
            product {
              description
            }
          }
          ... on InstantPaperEntity {
            resourceId
            type
            product {
              sku
              type
              name
              image
            }
          }
          ... on DeviceEntity {
            resourceId
            type
            product {
              sku
              type
              name
              image
            }
          }
        }
      }
    }
  }
`;

export default GET_SUBSCRIPTIONS;
