export const formatDate = (inputDateString: string): string => {
  const options: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric'
  };
  const date = new Date(inputDateString);
  const formattedDate = date.toLocaleDateString('en-US', options);
  return formattedDate;
};

export const formatFullDate = (inputDateString: string): string => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: '2-digit'
  };
  const date = new Date(inputDateString);
  const formattedDate = date.toLocaleDateString('en-US', options);
  return formattedDate;
};

export const formatExpirationDate = (inputDateString: string): string => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: '2-digit'
  };
  const date = new Date(inputDateString);
  const formattedDate = date.toLocaleDateString('en-US', options);
  return formattedDate;
};

export const formatDateDay = (inputDateString: string): string => {
  const options: Intl.DateTimeFormatOptions = {
    day: 'numeric'
  };
  const date = new Date(inputDateString);
  const formattedDate = date.toLocaleDateString('en-US', options);
  return formattedDate;
};

export const formatOrdinalAbreviation = (day: string): string => {
  const dayNumber = Number(day);
  const ordinal =
    dayNumber > 3 && dayNumber < 21
      ? 'th'
      : ['th', 'st', 'nd', 'rd', 'th'][dayNumber % 10 > 3 ? 0 : dayNumber % 10];
  return ordinal;
};
