import { IconArrowCorners, IconXCircle } from '@veneer/core/';
import React from 'react';
import {
  CancelHPInstantInkButtonClicked,
  CancelHPAllInButtonClicked,
  ChangeYourPlanButtonClicked,
  ChangeYourPlanAllInButtonClicked
} from 'src/utils/analytics';
import { SubscriptionsWarningState } from 'src/utils/enums';
import { URLS } from 'src/utils/urls';
import { SubscriptionsCardProps } from '../SubscriptionsCard/types';
import getSubscriptionWarningState from 'src/helpers/getSubscriptionWarningState';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import {
  Card,
  ROW_TYPE,
  SubscriptionType
} from '@hpx-core-experiences/react-my-account-commons/dist/index';
import { CardRowProps } from '@hpx-core-experiences/react-my-account-commons/dist/components/Card/CardWrapper/CardRow';

export const MoreOptions = ({ subscription }: SubscriptionsCardProps) => {
  const { translate } = useDependencyManagerContext();
  const subscriptionState = getSubscriptionWarningState(
    subscription?.state,
    subscription?.type
  );

  const rows = [] as CardRowProps[];
  rows.push({
    index: 0,
    icon: <IconArrowCorners />,
    type: ROW_TYPE.EXTERNAL,
    content: translate(
      'subscriptions.changeYourPlan',
      'Change your plan'
    ) as string,
    link: {
      url:
        subscription?.type === SubscriptionType.INSTANT_INK
          ? URLS.ChangeYourPlanPrint
          : URLS.ChangeYourPlanAllIn,
      dataTestId: 'ChangeYourPlanButton'
    },
    analyticsEvent:
      subscription?.type === SubscriptionType.INSTANT_INK
        ? ChangeYourPlanButtonClicked
        : ChangeYourPlanAllInButtonClicked
  });

  const canCancel = subscriptionState !== SubscriptionsWarningState.INACTIVE;

  if (canCancel)
    rows.push({
      index: 1,
      icon: <IconXCircle />,
      type: ROW_TYPE.EXTERNAL,
      content: `${translate('subscriptions.cancelYour', 'Cancel your')} ${
        subscription?.type === SubscriptionType.HP_ALL_IN
          ? translate('common.allInPrintPlan', 'HP All-In Plan')
          : translate('common.instantInk', 'HP Instant Ink')
      }`,
      link: {
        url:
          subscription?.type === SubscriptionType.INSTANT_INK
            ? URLS.CancelHPInstantInk
            : URLS.ChangeYourPlanAllIn,
        dataTestId: 'CancelHPInstantInkButton'
      },
      analyticsEvent:
        subscription?.type === SubscriptionType.INSTANT_INK
          ? CancelHPInstantInkButtonClicked
          : CancelHPAllInButtonClicked
    });

  return (
    <Card
      title={{
        content: translate('common.moreOptions', 'More options') as string
      }}
      rows={rows}
      data-testid="more-options-section"
    />
  );
};
