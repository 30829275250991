import { SubscriptionType } from '@hpx-core-experiences/react-my-account-commons/dist/index';
import { SubscriptionsState, SubscriptionsWarningState } from 'src/utils/enums';

const instantInkSubscriptionStateToWarningState: {
  [key: string]: SubscriptionsWarningState;
} = {
  // Pending
  [SubscriptionsState.SUBSCRIBED_NO_PENS]: SubscriptionsWarningState.PENDING,
  [SubscriptionsState.SUBSCRIBED_NO_PRINTER]: SubscriptionsWarningState.PENDING,
  // Active
  [SubscriptionsState.SUBSCRIBED]: SubscriptionsWarningState.ACTIVE,
  // Aggregation translates subscribed to active
  [SubscriptionsState.ACTIVE]: SubscriptionsWarningState.ACTIVE,
  // Expiring
  [SubscriptionsState.INITIATED_UNSUBSCRIBE]:
    SubscriptionsWarningState.EXPIRING,
  // Error
  [SubscriptionsState.SUSPENDED]: SubscriptionsWarningState.ERROR,
  // Inactive
  [SubscriptionsState.UNSUBSCRIBED]: SubscriptionsWarningState.INACTIVE,
  [SubscriptionsState.OBSOLETE]: SubscriptionsWarningState.INACTIVE
};

const hpAllInSubscriptionStateToWarningState: {
  [key: string]: SubscriptionsWarningState;
} = {
  // Pending
  [SubscriptionsState.PENDING]: SubscriptionsWarningState.PENDING,
  // Active
  [SubscriptionsState.ACTIVE]: SubscriptionsWarningState.ACTIVE,
  // Expiring
  [SubscriptionsState.SUSPENDED]: SubscriptionsWarningState.EXPIRING,
  // Error
  [SubscriptionsState.ERROR]: SubscriptionsWarningState.ERROR,
  // Inactive
  [SubscriptionsState.INACTIVE]: SubscriptionsWarningState.INACTIVE
};

const getSubscriptionWarningState = (
  state: string,
  type: string
): SubscriptionsWarningState => {
  if (type === SubscriptionType.HP_ALL_IN) {
    return hpAllInSubscriptionStateToWarningState[state];
  }

  return instantInkSubscriptionStateToWarningState[state];
};

export default getSubscriptionWarningState;
