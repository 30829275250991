import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useWebcomponent } from '@hpx-core-experiences/react-my-account-commons/dist/index';
import { createGraphQLClient, Platform, Stack } from '@clientos/graphql-client';
import { createGraphQLProvider } from '@clientos/graphql-client/dist/react';
import { SubscriptionDetails } from 'src/pages/SubscriptionDetails';
import { Subscriptions } from 'src/pages/Subscriptions';
import useFeatureFlags from 'src/hooks/useFeatureFlags';
import { FeatureFlagsEnum } from 'src/utils/enums';
import { getQualtricsSurveyUrl } from 'src/utils/urls';
import { Container } from './styles';
import '../styles/globals.scss';
import { DependencyManagerProvider } from 'src/contexts/dependencyManager';
import * as T from './types';

const AppComponent: React.FC<T.AppPropsType> = () => {
  const {
    featureFlagValue: qualtricsSurvey,
    isLoading: isQualtricsSurveyLoading
  } = useFeatureFlags(FeatureFlagsEnum.qualtricsSurvey);

  const showQualtricsSurvey = qualtricsSurvey && !isQualtricsSurveyLoading;

  useWebcomponent({
    url: showQualtricsSurvey ? getQualtricsSurveyUrl() : '',
    scriptType: 'text/javascript',
    scriptId: 'qualtrics-survey',
    target: 'head'
  });

  return (
    <Container data-testid="subscriptions-page">
      <BrowserRouter>
        <Routes>
          <Route
            path="/:country/:language/subscriptions"
            element={<Subscriptions />}
          />
          <Route
            path="/:country/:language/subscriptions/:subscriptionId"
            element={<SubscriptionDetails />}
          />
          <Route
            path="*"
            element={<Subscriptions />}
          />
        </Routes>
      </BrowserRouter>
    </Container>
  );
};

// This component wraps your main component with the DependencyManagerProvider
// This provider is responsible to manage global dependencies to your component
const App: React.FC<T.AppPropsType> = (props) => {
  const stack: Stack = (window as any).Shell?.v1.app.getAuthStack();
  const client = createGraphQLClient({
    authTokenCallback: async () => {
      return await (props.authProvider as any).getAccessToken();
    },
    platform: Platform.WEB,
    stack: stack,
    isShellPortal: true
  });

  const GraphQLProvider = createGraphQLProvider(React);

  return (
    <DependencyManagerProvider>
      <GraphQLProvider
        client={client}
        // eslint-disable-next-line react/no-children-prop
        children={<AppComponent {...props} />}
      />
    </DependencyManagerProvider>
  );
};

export default App;
