import { IconCreditCard } from '@veneer/core';
import React from 'react';
import {
  CardContainer,
  Link,
  History,
  HistoryContent,
  EmptyContent
} from 'src/styles/styles';
import {
  HpAllInAddPaymentMethodHyperLinkClicked,
  InstantInkAddPaymentMethodHyperLinkClicked,
  PaymentHistoryHyperLinkClicked,
  PaymentHistoryInstantInkHyperLinkClicked,
  publishEvent
} from 'src/utils/analytics';
import { formatDateDay, formatOrdinalAbreviation } from 'src/utils/masks';
import { URLS } from 'src/utils/urls';
import { SubscriptionsCardProps } from '../SubscriptionsCard/types';
import PaymentFlag from './PaymentFlag';
import { getResourceId } from 'src/utils/utils';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import {
  Card,
  SubscriptionType
} from '@hpx-core-experiences/react-my-account-commons/dist/index';

export const Payment = ({ subscription }: SubscriptionsCardProps) => {
  const { translate } = useDependencyManagerContext();

  const resourceId = getResourceId(subscription);

  const billedDay = formatDateDay(
    subscription?.billing?.nextBillingCycle?.chargeDate
  );

  const paymentHistoryLink =
    subscription?.type === 'HP_ALL_IN'
      ? URLS.AccountHistoryAllIn
      : URLS.AccountHistoryInk;

  const noPayments = (
    <EmptyContent>
      <IconCreditCard />
      <div className="caption">
        <a
          data-testid="AddPaymentMethodLink"
          href={URLS.UnifiedShippingBillingURL}
          target="_blank"
          rel="noreferrer"
          onClick={() =>
            publishEvent(
              subscription?.type === SubscriptionType.HP_ALL_IN
                ? HpAllInAddPaymentMethodHyperLinkClicked(
                    subscription?.resourceId,
                    subscription?.startDate
                  )
                : InstantInkAddPaymentMethodHyperLinkClicked(
                    subscription?.resourceId,
                    subscription?.startDate
                  )
            )
          }
        >
          {`${translate('subscriptions.addPaymentMethod', 'Add payment method')}`}
        </a>
      </div>
    </EmptyContent>
  );

  const payments = (
    <CardContainer>
      <PaymentFlag
        {...subscription?.billing?.paymentMethod}
        id={resourceId}
        startDate={subscription?.startDate}
        subscriptionType={subscription?.type}
      />
      <History>
        <HistoryContent>
          <div>
            <b className="caption">
              {'$'}
              {subscription?.price?.price}
            </b>
            {` ${translate('subscriptions.plusTax', '+ tax')}`}
          </div>
          {billedDay && (
            <div className="caption">
              {`${translate(
                'subscriptions.billedMonthly',
                'billed monthly on the'
              )} ${billedDay}${formatOrdinalAbreviation(billedDay)}`}
            </div>
          )}
        </HistoryContent>
        <div className="caption">
          <Link
            data-testid="PaymentHistoryLink"
            href={paymentHistoryLink}
            target="_blank"
            rel="noreferrer"
            onClick={() =>
              publishEvent(
                subscription?.type === SubscriptionType.HP_ALL_IN
                  ? PaymentHistoryHyperLinkClicked(
                      resourceId,
                      subscription?.startDate
                    )
                  : PaymentHistoryInstantInkHyperLinkClicked(
                      resourceId,
                      subscription?.startDate
                    )
              )
            }
          >
            {`${translate('subscriptions.paymentHistory', 'Payment history')}`}
          </Link>
        </div>
      </History>
    </CardContainer>
  );

  return (
    <Card
      title={{
        content: translate('subscriptions.payment', 'Payment') as string
      }}
      data-testid="payment-section"
    >
      {subscription?.billing?.paymentMethod?.type ? payments : noPayments}
    </Card>
  );
};
