import { Button } from '@veneer/core';
import React, { useEffect } from 'react';
import { AnalyticsEventPropTypes, publishEvent } from 'src/utils/analytics';
import {
  ErrorContent,
  ErrorTextContainer,
  ErrorTopWrapper,
  StyledIconTools,
  Section
} from './styles';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';

const ErrorBoundary = ({
  analyticsEvent
}: {
  analyticsEvent: AnalyticsEventPropTypes;
}) => {
  useEffect(() => {
    publishEvent(analyticsEvent);
  }, [analyticsEvent]);

  const { translate } = useDependencyManagerContext();

  return (
    <Section
      appearance="outlined"
      content={
        <ErrorContent data-testid="error-boundary">
          <ErrorTopWrapper>
            <StyledIconTools />
            <ErrorTextContainer>
              <div className="subtitle-large">
                {`${translate('common.itsNotYou', 'It’s not you, it’s us...')}`}
              </div>
              <div className="body">
                {`${translate(
                  'common.thereWasAnInterruption',
                  'There was an interruption while loading this information.'
                )}`}
              </div>
            </ErrorTextContainer>
          </ErrorTopWrapper>
          <Button
            appearance="secondary"
            onClick={() => {
              window.location.reload();
            }}
          >
            {`${translate('common.tryAgain', 'Please try again')}`}
          </Button>
        </ErrorContent>
      }
    />
  );
};

export default ErrorBoundary;
