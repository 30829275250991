import { gql } from '@clientos/graphql-client';

const GET_SUBSCRIPTIONS = gql`
  query GetSubscriptions {
    account {
      subscriptions {
        resourceId
        friendlyId
        type
        devices {
          items {
            identity {
              friendlyName
              makeAndModel {
                name
              }
            }
          }
        }
        state
        billing {
          nextBillingCycle {
            chargeDate
          }
        }
        entities {
          ... on InstantInkEntity {
            resourceId
            type
            product {
              description
            }
          }
          ... on InstantPaperEntity {
            resourceId
            type
          }
          ... on DeviceEntity {
            resourceId
            type
            product {
              sku
              type
              name
              image
            }
          }
        }
      }
    }
  }
`;

export default GET_SUBSCRIPTIONS;
