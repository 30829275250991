import { IconGhostWaveWall } from '@veneer/core';
import tokens from '@veneer/tokens';
import styled from 'styled-components';

export const ContentSectionNoSubscriptions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${tokens.layout.size8};
  gap: ${tokens.layout.size8};
`;

export const IconGhost = styled(IconGhostWaveWall)`
  && {
    color: ${tokens.color.gray9};
  }
`;

export const NoSubscriptions = styled.body`
  && {
    color: ${tokens.color.gray9};
  }
`;

export const HPStoreSubscriptionsInfo = styled.div`
  display: flex;
  flex: 1;

  p {
    font-size: 16px;
    font-weight: 400;
    height: 24px;
  }
`;
