import tokens from '@veneer/tokens';
import styled from 'styled-components';

export const PaymentFlagImage = styled('img')`
  height: 100%;
  width: ${(props) => (props.width ? `${props.width}px` : '40px')};
`;

export const HiperLink = styled.a`
  display: flex;
  gap: 2px;
  align-items: center;

  color: ${tokens.color.hpBlue7};
`;

export const Expired = styled.div`
  color: ${tokens.color.red7};
`;
