import amex from './amex.svg';
import citizens from './citizens.svg';
import discover from './discover.svg';
import mastercard from './mastercard.svg';
import paypal from './paypal.svg';
import visa from './visa.svg';
import qrcode from './qrcode.svg';
import allIn from './hp-all-in-plan-logo.svg';
import allInHorizontal from './hp-all-in-plan-logo-horizontal.svg';
import carePack from './hp-care-pack-logo.svg';

const Images = {
  amex,
  discover,
  citizens,
  mastercard,
  paypal,
  visa,
  qrcode,
  allIn,
  allInHorizontal,
  carePack
};

export default Images;
