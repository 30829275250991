import {
  getSubscriptionCommercialState,
  CommercialSubscriptionStates,
  ApiSubscriptionStates
} from '@hpx-core-experiences/react-my-account-commons/dist/index';
import React from 'react';
import { SubscriptionsCard } from 'src/components/SubscriptionsCard';
import { SubscriptionsProps } from 'src/components/SubscriptionsCard/types';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import { Group, GroupName, SubGroup } from './styles';

interface CardsProps {
  subscriptions: SubscriptionsProps[];
}

const Cards = ({ subscriptions }: CardsProps) => {
  const { translate } = useDependencyManagerContext();

  const activeOrder: (CommercialSubscriptionStates | ApiSubscriptionStates)[] =
    [
      CommercialSubscriptionStates.ERROR,
      CommercialSubscriptionStates.EXPIRING,
      CommercialSubscriptionStates.PENDING,
      CommercialSubscriptionStates.ACTIVE
    ];

  const active =
    subscriptions
      ?.filter(
        (sub: SubscriptionsProps) =>
          getSubscriptionCommercialState(sub.state, sub.type) !==
          CommercialSubscriptionStates.INACTIVE
      )
      .sort((a, b) => {
        // Sort by activeOrder
        const stateA = getSubscriptionCommercialState(a.state, a.type);
        const stateB = getSubscriptionCommercialState(b.state, b.type);
        const indexA = activeOrder.indexOf(stateA);
        const indexB = activeOrder.indexOf(stateB);

        // If stateA or stateB is not in activeOrder, place it at the end
        const adjustedIndexA = indexA === -1 ? activeOrder.length : indexA;
        const adjustedIndexB = indexB === -1 ? activeOrder.length : indexB;

        return adjustedIndexA - adjustedIndexB;
      }) || [];

  const inactive =
    subscriptions?.filter(
      (sub: SubscriptionsProps) =>
        getSubscriptionCommercialState(sub.state, sub.type) ===
        CommercialSubscriptionStates.INACTIVE
    ) || [];

  const showGroup = active.length > 0 && inactive.length > 0;

  return (
    <>
      {showGroup ? (
        <>
          <Group>
            <GroupName data-testid="active-group">{`${translate('common.active', 'Active')}`}</GroupName>
            <SubGroup showGroup>
              {active.map((item: SubscriptionsProps) => (
                <SubscriptionsCard
                  key={item.resourceId}
                  subscription={item}
                />
              ))}
            </SubGroup>
          </Group>
          <Group>
            <GroupName data-testid="inactive-group">{`${translate('common.inactive', 'Inactive')}`}</GroupName>
            <SubGroup showGroup>
              {inactive.map((item: SubscriptionsProps) => (
                <SubscriptionsCard
                  key={item.resourceId}
                  subscription={item}
                />
              ))}
            </SubGroup>
          </Group>
        </>
      ) : (
        <>
          {active.length > 0 && (
            <Group>
              <SubGroup>
                {active.map((item: SubscriptionsProps) => (
                  <SubscriptionsCard
                    key={item.resourceId}
                    subscription={item}
                  />
                ))}
              </SubGroup>
            </Group>
          )}
          {inactive.length > 0 && (
            <Group>
              <SubGroup>
                {inactive.map((item: SubscriptionsProps) => (
                  <SubscriptionsCard
                    key={item.resourceId}
                    subscription={item}
                  />
                ))}
              </SubGroup>
            </Group>
          )}
        </>
      )}
    </>
  );
};

export default Cards;
