export const transformDate = (dateString: string) => {
  if (!dateString) {
    return '';
  }

  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'UTC'
  };
  return date.toLocaleDateString('en-US', options);
};
