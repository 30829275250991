import React from 'react';
import Images from 'src/assets/images/index';
import { LogoHpInstantInk } from '@veneer/core';
import { SubscriptionType } from '@hpx-core-experiences/react-my-account-commons/dist/index';

type Props = {
  type: keyof typeof SubscriptionType | 'ALL_IN_HORIZONTAL';
} & Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'src'>;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function Logo({ type, ...rest }: Props) {
  let src = '';
  let alt = '';

  switch (type) {
    case SubscriptionType.HP_ALL_IN: {
      src = Images.allIn;
      alt = 'HP All In';
      break;
    }
    case 'ALL_IN_HORIZONTAL': {
      src = Images.allInHorizontal;
      alt = 'HP All In';
      break;
    }
    default: {
      src = Images.carePack;
      alt = 'HP Care Pack';
      break;
    }
  }

  return (
    <>
      {type == SubscriptionType.INSTANT_INK && (
        <LogoHpInstantInk
          appearance="multicolor"
          stacked
        />
      )}
      {(type === SubscriptionType.HP_ALL_IN ||
        type === 'ALL_IN_HORIZONTAL' ||
        type === SubscriptionType.HP_CARE_PACK) && (
        <img
          src={src}
          alt={alt}
          {...rest}
        />
      )}
    </>
  );
}
