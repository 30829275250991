import { Stack } from '@jarvis/web-stratus-client';

export const HP_SMART_PROD_URL = 'https://www.hpsmart.com/ucde';
export const HP_SMART_STAGE_URL =
  'https://consumer.stage.portalshell.int.hp.com';

export const QUALTRICS_SURVEY_PROD_URL =
  'https://znaid9q7g0kclquju-hpexperience.siteintercept.qualtrics.com/SIE/?Q_ZID=ZN_aid9q7g0kClquJU';
export const QUALTRICS_SURVEY_TEST_URL =
  'https://zncuabwr7p241aiia-hpexperience.siteintercept.qualtrics.com/SIE/?Q_ZID=ZN_cuaBwr7P241aIia';

export const getHpSmartUrl = (): string => {
  const stack: Stack = (window as any).Shell?.v1.app.getAuthStack();
  return stack === Stack.prod ? HP_SMART_PROD_URL : HP_SMART_STAGE_URL;
};

export const getQualtricsSurveyUrl = (): string => {
  const stack: Stack = (window as any).Shell?.v1.app.getAuthStack();
  return stack === Stack.prod
    ? QUALTRICS_SURVEY_PROD_URL
    : QUALTRICS_SURVEY_TEST_URL;
};

const BASE_VIRTUAL_AGENT_URL = 'https://virtualagent.hpcloud.hp.com/?';

const BASE_HP_SHOP_URL = 'https://www.hp.com/us-en/shop/';

export const URLS = {
  UnifiedShippingBillingURL: `${getHpSmartUrl()}/account-details/shipping-billing`,
  ChangeYourPlanAllIn: `${getHpSmartUrl()}/account-details/subscriptions`,
  AllInShipment: `${getHpSmartUrl()}/hp-all-in-print-plan/shipment-tracking`,
  AccountHistoryAllIn: `${getHpSmartUrl()}/account-details/statements`,
  CancelHPInstantInk: `${getHpSmartUrl()}/print_plans/cancellation`,
  InkShipment: `${getHpSmartUrl()}/print_plans/ink_shipment`,
  AccountHistoryInk: `${getHpSmartUrl()}/print_plans/account_history`,
  ChangeYourPlanPrint: `${getHpSmartUrl()}/print_plans/my_account`,
  InstantInkOverview: `${getHpSmartUrl()}/print_plans`,
  HpAllInOverview: `${getHpSmartUrl()}/hp-all-in-print-plan/overview`,

  ProLiveSupport:
    'https://support.hp.com/us-en/contact/all-in-plan-serial-number',
  VirtualAgent: `${BASE_VIRTUAL_AGENT_URL}BotClient=HPSA&BotSubClient=UnifiedMyAccount&query=&SerialNumber=&PL=&ProductNumber=&ProductName=&ProductNameOID=&ProductSeriesName=&ProductSeriesNameOID=&CC=us&LC=en`,
  HPShop: `${BASE_HP_SHOP_URL}LogonForm`,
  ChatWithVirtualAssistant: `${BASE_VIRTUAL_AGENT_URL}botClient=HPSA&amp;lc=en&amp;cc=US&amp;botSubClient=UnifiedMyAccount&amp;psc=true&amp;t=667fd991-f480-483b-ac66-138373c4e5e4&amp;sessionID=6dcce90a02823489d87952dc57b86837`,
  ImMissingInk: `${BASE_VIRTUAL_AGENT_URL}botClient=HPSmart&lc=en&cc=US&botSubClient=UCDE&psc=true&t=667fd991-f480-483b-ac66-138373c4e5e4&sessionID=6dcce90a02823489d87952dc57b86837`,
  HPStoreSubscriptions: `${BASE_HP_SHOP_URL}MySubscriptionsView?catalogId=10051&storeId=10151&langId=-1&krypto=x%2BA0P%2B5z7GkBQ4j7anxDpnP9CCs1g42ECjoCgMPS97yvLk5NOnpgg5Plv22EZMTeuNS0XNYrKWC5ih09y2wiPb3VqyjpBtui6vsMca550r137z7L29cRE7fSWSRS8xGl&currentPage=SigninPage&ddkey=https%3AETRLogonFlow`,
  MyBillWasNotWhatIExpected: `${BASE_VIRTUAL_AGENT_URL}botclient=HPSA&botsubclient=UnifiedMyAccount&query=ii_billingIssue`,
  GetInstantInkSupport: `https://support.hp.com/us-en/service/hp-instant-ink-series/5264756`
};
