import { IconCreditCard, IconExternalLink } from '@veneer/core';
import React, { useMemo } from 'react';
import Images from 'src/assets/images/index';
import formatPaymentMethodFlag from 'src/helpers/getPaymentFlag';
import { Gap8, Link } from 'src/styles/styles';
import {
  HpAllInManagePaymentMethodHyperLinkClicked,
  InstantInkManagePaymentMethodHyperLinkClicked,
  publishEvent
} from 'src/utils/analytics';
import { URLS } from 'src/utils/urls';
import { Expired, HiperLink, PaymentFlagImage } from './styles';
import getExpirationStatus from 'src/helpers/getExpirationStatus';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';

export interface PaymentFlagProps {
  id?: string;
  startDate?: string | Date;
  cardType?: string;
  type?: string;
  lastFour?: string;
  expirationDate?: string;
  name?: string;
  email?: string;
  subscriptionType?: string;
}

export default function PaymentFlag({
  id,
  startDate,
  cardType,
  type,
  lastFour,
  expirationDate,
  name,
  email,
  subscriptionType
}: Readonly<PaymentFlagProps>): JSX.Element {
  const { translate } = useDependencyManagerContext();
  const formattedPaymentFlag = formatPaymentMethodFlag(type);
  const formattedCreditCardFlag = formatPaymentMethodFlag(cardType);

  const analyticsEvent =
    subscriptionType === 'HP_ALL_IN'
      ? HpAllInManagePaymentMethodHyperLinkClicked(id, startDate)
      : InstantInkManagePaymentMethodHyperLinkClicked(id, startDate);

  const expireDate = useMemo(() => {
    if (!expirationDate) return null;

    const isExpired = getExpirationStatus(expirationDate);
    if (isExpired) {
      return (
        <Expired className="caption">{`${translate(
          'payments.expired',
          'Expired:'
        )} ${expirationDate}`}</Expired>
      );
    }

    return (
      <div className="caption">{`${translate(
        'payments.expires',
        'Expires:'
      )} ${expirationDate}`}</div>
    );
  }, [expirationDate, translate]);

  const renderCardInformation = useMemo(() => {
    const isFormatedCreditCardMasked = lastFour && lastFour.length === 4;

    return (
      <>
        <div className="caption">
          {isFormatedCreditCardMasked ? `XXXX-XXXX-XXXX-${lastFour}` : lastFour}
        </div>
        {expireDate}
        <div className="caption">
          <Link
            data-testid="creditCardChangePaymentMethod"
            href={URLS.UnifiedShippingBillingURL}
            target="_blank"
            rel="noreferrer"
            onClick={() => publishEvent(analyticsEvent)}
          >
            {`${translate('subscriptions.managePaymentMethod', 'Manage payment method')}`}
          </Link>
        </div>
      </>
    );
  }, [analyticsEvent, expireDate, lastFour, translate]);

  const renderPaymentCreditCardFlag = (formattedCardFlag: string) => {
    switch (formattedCardFlag) {
      case 'americanexpress':
        return (
          <Gap8>
            <PaymentFlagImage
              data-testid="cardImgAmericanexpress"
              src={Images.amex}
            />
            {renderCardInformation}
          </Gap8>
        );
      case 'discover':
        return (
          <Gap8>
            <PaymentFlagImage
              data-testid="cardImgDiscover"
              src={Images.discover}
            />
            {renderCardInformation}
          </Gap8>
        );
      case 'mastercard':
        return (
          <Gap8>
            <PaymentFlagImage
              data-testid="cardImgMastercard"
              src={Images.mastercard}
            />
            {renderCardInformation}
          </Gap8>
        );
      case 'visa':
        return (
          <Gap8>
            <PaymentFlagImage
              data-testid="cardImgVisa"
              src={Images.visa}
            />
            {renderCardInformation}
          </Gap8>
        );
      default:
        return (
          <IconCreditCard
            size={28}
            data-testid="cardImgGeneric"
          />
        );
    }
  };

  switch (formattedPaymentFlag) {
    case 'paypal':
      return (
        <Gap8>
          <PaymentFlagImage
            data-testid="cardImgPaypal"
            src={Images.paypal}
            width="75"
          />
          <div />
          {email && <div className="caption">{email}</div>}
          <div className="caption">
            <Link
              data-testid="paypalChangePaymentMethod"
              href={URLS.UnifiedShippingBillingURL}
              target="_blank"
              rel="noreferrer"
              onClick={() => publishEvent(analyticsEvent)}
            >
              {`${translate('subscriptions.managePaymentMethod', 'Manage payment method')}`}
            </Link>
          </div>
        </Gap8>
      );
    case 'citizens':
      return (
        <Gap8>
          <PaymentFlagImage
            width="125px"
            data-testid="cardImgCitizens"
            src={Images.citizens}
          />
          <div className="caption">{name}</div>
          <HiperLink
            data-testid="citizensChangePaymentMethod"
            href={URLS.UnifiedShippingBillingURL}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => publishEvent(analyticsEvent)}
          >
            <div className="caption">
              {`${translate('subscriptions.manageCitizensPay', 'Manage on Citizens Pay')}`}
            </div>
            <IconExternalLink
              size={20}
              color={'hpBlue7'}
              customStyle={{ marginLeft: '4px' }}
            />
          </HiperLink>
        </Gap8>
      );
    case 'creditcard':
      return renderPaymentCreditCardFlag(formattedCreditCardFlag);
    default:
      return (
        <IconCreditCard
          size={28}
          data-testid="cardImgGeneric"
        />
      );
  }
}
