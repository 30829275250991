import { useQuery } from '@clientos/graphql-client/dist/react';
import React, { useEffect } from 'react';
import ErrorBoundary from 'src/App/ErrorBoundary';
import {
  Loading,
  SubscriptionType
} from '@hpx-core-experiences/react-my-account-commons/dist/index';
import { Content, Header } from 'src/App/styles';
import GET_SUBSCRIPTIONS from 'src/graphql/getSubscriptions';
import { Link, Section } from 'src/styles/styles';
import {
  HPStoreSubscriptionHyperLinkClicked,
  ScreenDisplayed,
  getModuleDisplayedError,
  publishEvent
} from 'src/utils/analytics';
import { URLS } from 'src/utils/urls';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import Cards from './Cards';
import {
  ContentSectionNoSubscriptions,
  HPStoreSubscriptionsInfo,
  IconGhost,
  NoSubscriptions
} from './styles';

export const Subscriptions = () => {
  const { translate } = useDependencyManagerContext();

  const htmlString = translate(
    'subscriptions.findMrePlansServicesStoreSubscriptions',
    'Find more of your plans & services in your {{tagReplaceStart}}HP Store subscriptions{{tagReplaceEnd}}.'
  );

  const [start, middle, end] = htmlString.split(
    /{{\/?tagReplaceStart}}|{{\/?tagReplaceEnd}}/
  );

  const { loading, error, data } = useQuery(GET_SUBSCRIPTIONS);

  const subscriptionsLength = data?.account?.subscriptions?.length || 0;

  const hpInstantInkLength = data?.account?.subscriptions?.filter(
    (item: { type: string }) => item.type == SubscriptionType.INSTANT_INK
  )?.length;

  const hpAllInLength = data?.account?.subscriptions?.filter(
    (item: { type: string }) => item.type == SubscriptionType.HP_ALL_IN
  )?.length;

  useEffect(() => {
    if (
      subscriptionsLength != undefined &&
      hpInstantInkLength != undefined &&
      hpAllInLength != undefined
    ) {
      publishEvent(
        ScreenDisplayed(subscriptionsLength, hpInstantInkLength, hpAllInLength)
      );
    }
  }, [hpAllInLength, hpInstantInkLength, subscriptionsLength]);

  return (
    <>
      <Header>
        <h4>{`${translate('menu.subscriptions', 'Subscriptions & services')}`}</h4>
        <p className="body">
          {`${translate(
            'subscriptions.viewOrManageCurrentPastSubscriptions',
            'View or manage your current and past HP subscriptions'
          )}`}
        </p>
      </Header>
      <Content data-testid="content-subscriptions">
        {loading && (
          <Section
            appearance="outlined"
            content={<Loading />}
          />
        )}
        {error && (
          <ErrorBoundary
            analyticsEvent={getModuleDisplayedError(error.message)}
          />
        )}
        {!loading && !error && subscriptionsLength > 0 && (
          <Cards subscriptions={data?.account?.subscriptions} />
        )}
        {!loading && !error && subscriptionsLength <= 0 && (
          <Section
            appearance="outlined"
            data-testid="section-no-subscriptions"
            content={
              <ContentSectionNoSubscriptions>
                <IconGhost size={40} />
                <NoSubscriptions>
                  {`${translate(
                    'subscriptions.noSubscriptionsYet',
                    'No subscriptions yet!'
                  )}`}
                </NoSubscriptions>
              </ContentSectionNoSubscriptions>
            }
          />
        )}
      </Content>
      <HPStoreSubscriptionsInfo>
        <p className="body">
          {start}
          <Link
            data-testid="hp-store-subscriptions-link"
            href={URLS.HPStoreSubscriptions}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => publishEvent(HPStoreSubscriptionHyperLinkClicked)}
          >
            {middle}
          </Link>
          {end}
        </p>
      </HPStoreSubscriptionsInfo>
    </>
  );
};
