import { tokens } from '@veneer/tokens';
import { MainTheme } from 'src/styles/styles';
import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  gap: ${tokens.layout.size5};
  padding: ${tokens.layout.size5} 40px;
  max-width: 1220px;
  margin: 0 auto;

  @media (${MainTheme.mediaQueries.medium}) {
    gap: ${tokens.layout.size8};
    padding: ${tokens.layout.size5} ${tokens.layout.size8};
  }

  @media (${MainTheme.mediaQueries.small}) {
    gap: ${tokens.layout.size5};
    padding: ${tokens.layout.size5};
  }

  @media (${MainTheme.mediaQueries.extraSmall}) {
    gap: ${tokens.layout.size4};
    padding: ${tokens.layout.size4};
  }

  @media (${MainTheme.mediaQueries.mobile}) {
    gap: ${tokens.layout.size4};
    padding: ${tokens.layout.size4};
  }
`;
export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${tokens.layout.size1};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.layout.size6};
  width: 100%;

  @media (${MainTheme.mediaQueries.mobile}) {
    gap: ${tokens.layout.size6};
  }

  @media (${MainTheme.mediaQueries.extraSmall}) {
    gap: ${tokens.layout.size5};
  }

  @media (${MainTheme.mediaQueries.small}) {
    gap: ${tokens.layout.size5};
  }
`;

export const DrillDownHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;

  svg {
    cursor: pointer;
  }

  .subtitle-regular {
    font-size: ${tokens.typography.size4};
  }
`;

export const ErrorAndLoading = styled.div`
  display: flex;
  justify-content: center;
`;
