import { PublishCdmEventsOptions } from '@jarvis/shell-commons/dist/interface/v1/analytics/types';
import { SubscriptionsWarningState } from './enums';
import { URLS } from './urls';
import { SubscriptionType } from '@hpx-core-experiences/react-my-account-commons/dist/index';

const activity = 'UnifiedAcctMgmt-v01';
const screenPath = '/ReactMyAccountSubscriptions/';
const screenName = 'SubscriptionsAndServices';
const eventDetailVersion = '1.5.0';
const screenPathSubscriptionDetails =
  '/ReactMyAccountSubscriptions/SubscriptionsAndServices/';
const screenPathSubscriptionDetailsError =
  '/ReactMyAccountSubscriptions/SubscriptionsAndServices/SubscriptionDetails/';

const screenNameSubscriptionDetails = 'SubscriptionDetails';
const screenNameSubscriptionsError = 'SubscriptionsError';
const screenNameSubscriptionDetailsError = 'SubscriptionDetailsError';

function getSubTypeNameFormatted(subscriptionType: SubscriptionType): string {
  switch (subscriptionType) {
    case SubscriptionType.INSTANT_INK:
      return 'InstantInk';
    case SubscriptionType.HP_ALL_IN:
      return 'HpAllIn';
    default:
      return 'Unknown';
  }
}

export type AnalyticsEventPropTypes = {
  actionAuxParams?: string;
  action: string;
  activity: string;
  screenPath: string;
  screenName: string;
  screenMode?: string;
  controlName?: string;
  controlDetail?: string;
  version: string;
};

export const publishEvent = (
  event: AnalyticsEventPropTypes,
  options?: PublishCdmEventsOptions
) => {
  const publishCdmEvents = (window as any)?.Shell?.v1?.analytics
    ?.publishCdmEvents;

  if (!publishCdmEvents) {
    return;
  }

  const dateTime = new Date().toISOString();
  const cdmEvents = {
    dateTime,
    eventDetailType:
      'com.hp.cdm.domain.telemetry.type.eventDetail.category.simpleUi.version.1',
    eventCategory: 'simpleUi',
    eventDetail: event,
    version: '1.5.0'
  };

  publishCdmEvents([cdmEvents], options);
};

const ACTIONS = {
  SCREEN_DISPLAYED: 'ScreenDisplayed',
  CONTROL_BUTTON_CLICKED: 'ControlButtonClicked',
  CONTROL_HYPERLINK_CLICKED: 'ControlHyperLinkClicked',
  MODULE_DISPLAYED: 'ModuleDisplayed'
};

export const ChangeYourPlanButtonClicked = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath: screenPathSubscriptionDetails,
  screenName: screenNameSubscriptionDetails,
  controlName: 'ChangeYourPlan',
  controlDetail: URLS.ChangeYourPlanPrint,
  version: eventDetailVersion
} as AnalyticsEventPropTypes;

export const ChangeYourPlanAllInButtonClicked = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath: screenPathSubscriptionDetails,
  screenName: screenNameSubscriptionDetails,
  controlName: 'ChangeYourPlan',
  controlDetail: URLS.ChangeYourPlanAllIn,
  version: eventDetailVersion
} as AnalyticsEventPropTypes;

export const CancelHPInstantInkButtonClicked = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath: screenPathSubscriptionDetails,
  screenName: screenNameSubscriptionDetails,
  controlName: 'CancelHPInstantInk',
  controlDetail: URLS.CancelHPInstantInk,
  version: eventDetailVersion
} as AnalyticsEventPropTypes;

export const CancelHPAllInButtonClicked = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath: screenPathSubscriptionDetails,
  screenName: screenNameSubscriptionDetails,
  controlName: 'CancelYourHpAllInPlan',
  controlDetail: URLS.ChangeYourPlanAllIn,
  version: eventDetailVersion
} as AnalyticsEventPropTypes;

export const GeneralControlButtonClicked = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath,
  screenName,
  version: eventDetailVersion
} as AnalyticsEventPropTypes;

export const GeneralControlHyperLinkClicked = {
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity,
  screenPath,
  screenName,
  version: eventDetailVersion
} as AnalyticsEventPropTypes;

export const SubscriptionDetailsButtonClicked = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath: screenPathSubscriptionDetails,
  screenName: screenNameSubscriptionDetails,
  version: eventDetailVersion
} as AnalyticsEventPropTypes;

export const SubscriptionDetailsHyperLinkClicked = {
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity,
  screenPath: screenPathSubscriptionDetails,
  screenName: screenNameSubscriptionDetails,
  version: eventDetailVersion
} as AnalyticsEventPropTypes;

export const ModuleDisplayedErrorSubscription = {
  action: ACTIONS.MODULE_DISPLAYED,
  activity,
  screenPath: screenPathSubscriptionDetails,
  screenName: screenNameSubscriptionsError,
  version: eventDetailVersion
} as AnalyticsEventPropTypes;

export const ModuleDisplayedErrorSubscriptionDetails = {
  action: ACTIONS.MODULE_DISPLAYED,
  activity,
  screenPath: screenPathSubscriptionDetailsError,
  screenName: screenNameSubscriptionDetailsError,
  version: eventDetailVersion
} as AnalyticsEventPropTypes;

export const HPStoreSubscriptionHyperLinkClicked = {
  ...GeneralControlHyperLinkClicked,
  controlName: 'DontSeeSubscriptions',
  controlDetail: URLS.HPShop
};

function parseType(type: keyof typeof SubscriptionType) {
  let controlName: string;
  if (type == SubscriptionType.INSTANT_INK) {
    controlName = 'HpInstantInk';
  } else if (type == SubscriptionType.HP_ALL_IN) {
    controlName = 'HpAllIn';
  } else {
    controlName = 'HpCarePack';
  }
  return controlName;
}

export function generateAnalyticsOfSubscriptionDetailsByType(
  type: keyof typeof SubscriptionType,
  id: string,
  startDate: string | Date
): AnalyticsEventPropTypes {
  const controlName = parseType(type);

  return {
    ...GeneralControlButtonClicked,
    controlName: `${controlName}SubscriptionDetails`,
    actionAuxParams: getActionAuxParamsSubscriptionsDetails(id, startDate)
  } as AnalyticsEventPropTypes;
}

export function generateAnalyticsOfScreenDisplayedByType(
  type: keyof typeof SubscriptionType
): AnalyticsEventPropTypes {
  const screenMode = parseType(type);

  return {
    action: ACTIONS.SCREEN_DISPLAYED,
    activity,
    screenMode,
    screenPath: screenPathSubscriptionDetails,
    screenName: screenNameSubscriptionDetails,
    version: eventDetailVersion
  } as AnalyticsEventPropTypes;
}

export function getActionAuxParamsSubscriptionsDetails(
  id: string,
  startDate: string | Date
): string {
  return `subscriptionStartDate=${
    startDate ?? new Date()
  }&subscriptionId=${id}`;
}

export function getModuleDisplayedError(
  errorMessage: string,
  type?: keyof typeof SubscriptionType
): AnalyticsEventPropTypes {
  return type != null
    ? ({
        ...ModuleDisplayedErrorSubscription,
        screenMode: parseType(type),
        actionAuxParams: `error=${errorMessage.slice(0, 256)}`
      } as AnalyticsEventPropTypes)
    : ({
        ...ModuleDisplayedErrorSubscription,
        actionAuxParams: `error=${errorMessage.slice(0, 256)}`
      } as AnalyticsEventPropTypes);
}

export function getModuleDisplayedSubscriptionsDetailsError(
  errorMessage: string,
  type?: keyof typeof SubscriptionType
): AnalyticsEventPropTypes {
  return type != null
    ? ({
        ...ModuleDisplayedErrorSubscriptionDetails,
        screenMode: parseType(type),
        actionAuxParams: `error=${errorMessage.slice(0, 256)}`
      } as AnalyticsEventPropTypes)
    : ({
        ...ModuleDisplayedErrorSubscriptionDetails,
        actionAuxParams: `error=${errorMessage.slice(0, 256)}`
      } as AnalyticsEventPropTypes);
}

export const ScreenDisplayed = (
  subscriptionsLength: number,
  hpInstantInkLength: number,
  hpAllInLength: number
) =>
  ({
    action: ACTIONS.SCREEN_DISPLAYED,
    activity,
    screenPath,
    screenName,
    version: eventDetailVersion,
    actionAuxParams: `totalSubscriptionsCt=${subscriptionsLength}&totalInstantInkCt=${hpInstantInkLength}&totalHpAllInCt=${hpAllInLength}`
  }) as AnalyticsEventPropTypes;

export const SubscriptionWarningModuleDisplayed = (
  subscriptionType: SubscriptionType,
  warningType: SubscriptionsWarningState
) =>
  ({
    action: ACTIONS.MODULE_DISPLAYED,
    activity,
    screenPath: screenPathSubscriptionDetails,
    screenName: screenNameSubscriptionDetails,
    screenMode: getSubTypeNameFormatted(subscriptionType),
    controlName: 'SubscriptionStatusBanner',
    version: eventDetailVersion,
    actionAuxParams: `warningType=${warningType?.toLowerCase()}`
  }) as AnalyticsEventPropTypes;

export const SubscriptionWarningButtonClicked = (
  subscriptionType: SubscriptionType,
  warningType: SubscriptionsWarningState
) =>
  ({
    action: ACTIONS.CONTROL_BUTTON_CLICKED,
    activity,
    screenPath: screenPathSubscriptionDetails,
    screenName: screenNameSubscriptionDetails,
    screenMode: getSubTypeNameFormatted(subscriptionType),
    version: eventDetailVersion,
    controlName: 'SubscriptionStatusBanner',
    controlDetail:
      subscriptionType === SubscriptionType.HP_ALL_IN
        ? URLS.HpAllInOverview
        : URLS.InstantInkOverview,
    actionAuxParams: `warningType=${warningType?.toLowerCase()}`
  }) as AnalyticsEventPropTypes;

export const InstantInkManagePaymentMethodHyperLinkClicked = (
  id: string,
  startDate: string | Date
) =>
  ({
    ...SubscriptionDetailsHyperLinkClicked,
    screenMode: 'HpInstantInk',
    controlName: 'ManagePaymentMethod',
    controlDetail: URLS.UnifiedShippingBillingURL,
    actionAuxParams: getActionAuxParamsSubscriptionsDetails(id, startDate)
  }) as AnalyticsEventPropTypes;

export const HpAllInManagePaymentMethodHyperLinkClicked = (
  id: string,
  startDate: string | Date
) =>
  ({
    ...SubscriptionDetailsHyperLinkClicked,
    screenMode: 'HpAllIn',
    controlName: 'ManagePaymentMethod',
    controlDetail: URLS.UnifiedShippingBillingURL,
    actionAuxParams: getActionAuxParamsSubscriptionsDetails(id, startDate)
  }) as AnalyticsEventPropTypes;

export const InstantInkAddPaymentMethodHyperLinkClicked = (
  id: string,
  startDate: string | Date
) =>
  ({
    ...SubscriptionDetailsHyperLinkClicked,
    screenMode: 'HpInstantInk',
    controlName: 'AddPaymentMethod',
    controlDetail: URLS.UnifiedShippingBillingURL,
    actionAuxParams: getActionAuxParamsSubscriptionsDetails(id, startDate)
  }) as AnalyticsEventPropTypes;

export const HpAllInAddPaymentMethodHyperLinkClicked = (
  id: string,
  startDate: string | Date
) =>
  ({
    ...SubscriptionDetailsHyperLinkClicked,
    screenMode: 'HpAllIn',
    controlName: 'AddPaymentMethod',
    controlDetail: URLS.UnifiedShippingBillingURL,
    actionAuxParams: getActionAuxParamsSubscriptionsDetails(id, startDate)
  }) as AnalyticsEventPropTypes;

export const MissingInkButtonClicked = (id: string, startDate: string | Date) =>
  ({
    ...SubscriptionDetailsButtonClicked,
    controlName: 'ImMissingInk',
    controlDetail: URLS.ImMissingInk,
    actionAuxParams: getActionAuxParamsSubscriptionsDetails(id, startDate)
  }) as AnalyticsEventPropTypes;

export const PaymentHistoryHyperLinkClicked = (
  id: string,
  startDate: string | Date
) =>
  ({
    ...SubscriptionDetailsHyperLinkClicked,
    controlName: 'PaymentHistory',
    controlDetail: URLS.AccountHistoryAllIn,
    actionAuxParams: getActionAuxParamsSubscriptionsDetails(id, startDate)
  }) as AnalyticsEventPropTypes;

export const PaymentHistoryInstantInkHyperLinkClicked = (
  id: string,
  startDate: string | Date
) =>
  ({
    ...SubscriptionDetailsHyperLinkClicked,
    controlName: 'PaymentHistory',
    controlDetail: URLS.AccountHistoryInk,
    actionAuxParams: getActionAuxParamsSubscriptionsDetails(id, startDate)
  }) as AnalyticsEventPropTypes;

export const ShipmentHistoryHyperLinkClicked = (
  id: string,
  startDate: string | Date
) =>
  ({
    ...SubscriptionDetailsHyperLinkClicked,
    controlName: 'ShipmentHistory',
    controlDetail: URLS.AllInShipment,
    actionAuxParams: getActionAuxParamsSubscriptionsDetails(id, startDate)
  }) as AnalyticsEventPropTypes;

export const ShipmentHistoryInstantInkHyperLinkClicked = (
  id: string,
  startDate: string | Date
) =>
  ({
    ...SubscriptionDetailsHyperLinkClicked,
    controlName: 'ShipmentHistory',
    controlDetail: URLS.InkShipment,
    actionAuxParams: getActionAuxParamsSubscriptionsDetails(id, startDate)
  }) as AnalyticsEventPropTypes;

export const OverviewInstantInkHyperLinkClicked = (
  id: string,
  startDate: string | Date
) =>
  ({
    ...SubscriptionDetailsHyperLinkClicked,
    controlName: 'InstantInkOverview',
    controlDetail: URLS.InstantInkOverview,
    actionAuxParams: getActionAuxParamsSubscriptionsDetails(id, startDate)
  }) as AnalyticsEventPropTypes;

export const OverviewAllInHyperLinkClicked = (
  id: string,
  startDate: string | Date
) =>
  ({
    ...SubscriptionDetailsHyperLinkClicked,
    controlName: 'HpAllInOverview',
    controlDetail: URLS.HpAllInOverview,
    actionAuxParams: getActionAuxParamsSubscriptionsDetails(id, startDate)
  }) as AnalyticsEventPropTypes;

export const HpAllInManageAddressHyperLinkClicked = (
  id: string,
  startDate: string | Date
) =>
  ({
    ...SubscriptionDetailsHyperLinkClicked,
    controlName: 'ChangeAddress',
    controlDetail: URLS.UnifiedShippingBillingURL,
    actionAuxParams: getActionAuxParamsSubscriptionsDetails(id, startDate)
  }) as AnalyticsEventPropTypes;

export const InstantInkManageAddressHyperLinkClicked = (
  id: string,
  startDate: string | Date
) =>
  ({
    ...SubscriptionDetailsHyperLinkClicked,
    controlName: 'ChangeAddress',
    controlDetail: URLS.UnifiedShippingBillingURL,
    actionAuxParams: getActionAuxParamsSubscriptionsDetails(id, startDate)
  }) as AnalyticsEventPropTypes;

export const ProLiveSupportButtonClicked = () =>
  ({
    ...SubscriptionDetailsButtonClicked,
    controlName: 'ProLiveSupport',
    controlDetail: URLS.ProLiveSupport
  }) as AnalyticsEventPropTypes;

export const CallSupportHyperLinkClicked = (phoneNumber: string) =>
  ({
    action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
    activity,
    screenPath: screenPathSubscriptionDetails,
    screenName: screenNameSubscriptionDetails,
    controlDetail: phoneNumber,
    controlName: 'CallSupportHpAllIn',
    version: eventDetailVersion
  }) as AnalyticsEventPropTypes;

export const MyBillWasNotWhatIExpectedButtonClicked = (
  id: string,
  startDate: string | Date
) =>
  ({
    ...SubscriptionDetailsButtonClicked,
    controlName: 'MyBillWasNotWhatIExpected',
    controlDetail: URLS.MyBillWasNotWhatIExpected,
    actionAuxParams: getActionAuxParamsSubscriptionsDetails(id, startDate)
  }) as AnalyticsEventPropTypes;

export const GetInstantInkSupportButtonClicked = (
  id: string,
  startDate: string | Date
) =>
  ({
    ...SubscriptionDetailsButtonClicked,
    controlName: 'GetInstantInkSupport',
    controlDetail: URLS.GetInstantInkSupport,
    actionAuxParams: getActionAuxParamsSubscriptionsDetails(id, startDate)
  }) as AnalyticsEventPropTypes;
