import { useQuery } from '@clientos/graphql-client/dist/react';
import { IconArrowLeft } from '@veneer/core';
import React, { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ErrorBoundary from 'src/App/ErrorBoundary';
import {
  Loading,
  SubscriptionType
} from '@hpx-core-experiences/react-my-account-commons/dist/index';
import { Content, DrillDownHeader } from 'src/App/styles';
import HaveQuestions from 'src/components/HaveQuestions';
import { MoreOptions } from 'src/components/MoreOptions';
import { Payment } from 'src/components/Payment';
import PlanDetails from 'src/components/PlanDetails';
import { Shipping } from 'src/components/Shipping';
import { SubscriptionWarning } from 'src/components/SubscriptionWarning';
import { NotificationType } from 'src/components/SubscriptionWarning/types';
import { SubscriptionsProps } from 'src/components/SubscriptionsCard/types';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import GET_SUBSCRIPTIONS_DETAILS from 'src/graphql/getSubscriptionDetails';
import getSubscriptionWarningState from 'src/helpers/getSubscriptionWarningState';
import {
  generateAnalyticsOfScreenDisplayedByType,
  getModuleDisplayedSubscriptionsDetailsError,
  publishEvent
} from 'src/utils/analytics';
import { SubscriptionsWarningState } from 'src/utils/enums';
import { formatFullDate } from 'src/utils/masks';
import { Section } from 'src/styles/styles';

type SubscriptionParamType = keyof typeof SubscriptionType;

export const SubscriptionDetails = () => {
  const { translate, northboundAPIs } = useDependencyManagerContext();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const navigation = northboundAPIs?.v1?.navigation;

  const path = navigation.location.pathname.split('/').pop();

  const resourceId = path.includes('?') ? path.split('?')[0] : path;

  const type = queryParams.get('type') as unknown as SubscriptionParamType;

  const { loading, error, data } = useQuery(GET_SUBSCRIPTIONS_DETAILS, {
    variables: {
      subscriptionFilter: {
        resourceId: resourceId,
        subscriptionType: type
      }
    }
  });

  const country = northboundAPIs?.v1?.localization.country;
  const language = northboundAPIs?.v1?.localization.language;

  const subscription: SubscriptionsProps = data?.account?.subscriptions[0];
  const subscriptionName =
    subscription?.type === SubscriptionType.HP_ALL_IN
      ? translate('common.allInPrintPlan', 'HP All-In Plan')
      : translate('common.instantInk', 'HP Instant Ink');

  const showSubscriptionWarning = useCallback(() => {
    const warningState = getSubscriptionWarningState(
      subscription?.state,
      subscription?.type
    );

    function renderSubscriptionWarningPending() {
      const htmlString = translate(
        'subscriptions.warningInactiveMessagePending',
        {
          defaultValue:
            'Your {{subscriptionName}} is almost ready! Click {{tagReplaceStart}}Next steps {{tagReplaceEnd}}to see updates and any actions you might need to take.',
          replace: { subscriptionName }
        }
      )
        .replace('{{tagReplaceStart}}', '<strong>')
        .replace('{{tagReplaceEnd}}', '</strong>');

      return (
        <SubscriptionWarning
          data={{
            type: 'informative' as NotificationType,
            label: translate('subscriptions.nextSteps', 'Next steps'),
            subscriptionType: subscription?.type,
            subscriptionState: SubscriptionsWarningState.PENDING,
            title: (
              <span
                data-testid="subscription-status-pending"
                dangerouslySetInnerHTML={{ __html: htmlString }}
              ></span>
            )
          }}
        />
      );
    }

    function renderSubscriptionWarningExpiring() {
      const chargeDate = formatFullDate(
        subscription?.billing?.nextBillingCycle?.chargeDate
      );

      const htmlString = translate(
        'subscriptions.warningInactiveMessageExpiring',
        {
          defaultValue:
            'Heads up! Your subscription and services will end on {{chargeDate}}. Click {{tagReplaceStart}}More details{{tagReplaceEnd}} to review any required actions or other information.',
          replace: { chargeDate }
        }
      )
        .replace('{{tagReplaceStart}}', '<strong>')
        .replace('{{tagReplaceEnd}}', '</strong>');

      return (
        <SubscriptionWarning
          data={{
            type: 'informative' as NotificationType,
            label: translate('subscriptions.moreDetails', 'More details'),
            subscriptionType: subscription?.type,
            subscriptionState: SubscriptionsWarningState.EXPIRING,
            title: (
              <span
                data-testid="subscription-status-expiring"
                dangerouslySetInnerHTML={{ __html: htmlString }}
              ></span>
            )
          }}
        />
      );
    }

    function renderSubscriptionWarningError() {
      return (
        <SubscriptionWarning
          data={{
            type: 'negative' as NotificationType,
            label: translate('subscriptions.fixNow', 'Fix now'),
            subscriptionType: subscription?.type,
            subscriptionState: SubscriptionsWarningState.ERROR,
            title: (
              <span data-testid="subscription-status-error">
                {`${translate(
                  'subscriptions.warningInactiveMessageError',
                  'Attention needed: There’s an issue with your subscription affecting service continuity. Please address promptly.'
                )}`}
              </span>
            )
          }}
        />
      );
    }

    function renderSubscriptionWarningInactive() {
      const htmlString = translate(
        'subscriptions.warningInactiveMessageInactive',
        'Your subscription has expired. Click {{tagReplaceStart}}More details {{tagReplaceEnd}}to review additional information and explore additional HP services.'
      );
      const [start, middle, end] = htmlString.split(
        /{{\/?tagReplaceStart}}|{{\/?tagReplaceEnd}}/
      );

      return (
        <SubscriptionWarning
          data={{
            type: 'informative' as NotificationType,
            label: translate('subscriptions.moreDetails', 'More details'),
            subscriptionType: subscription?.type,
            subscriptionState: SubscriptionsWarningState.INACTIVE,
            title: (
              <span data-testid="subscription-status-inactive">
                {start}
                <strong>{middle}</strong>
                {end}
              </span>
            )
          }}
        />
      );
    }

    switch (warningState) {
      case SubscriptionsWarningState.PENDING:
        return renderSubscriptionWarningPending();
      case SubscriptionsWarningState.ACTIVE:
        return;
      case SubscriptionsWarningState.EXPIRING:
        return renderSubscriptionWarningExpiring();
      case SubscriptionsWarningState.ERROR:
        return renderSubscriptionWarningError();
      case SubscriptionsWarningState.INACTIVE:
        return renderSubscriptionWarningInactive();
      default:
        return;
    }
  }, [
    subscription?.billing?.nextBillingCycle?.chargeDate,
    subscription?.state,
    subscription?.type,
    subscriptionName,
    translate
  ]);

  useEffect(() => {
    if (!subscription && !loading && !error) {
      navigation.push(`/${country}/${language}/subscriptions`);
    }
  }, [country, error, language, loading, navigation, subscription]);

  useEffect(() => {
    if (subscription?.resourceId && type && !loading && !error) {
      publishEvent(generateAnalyticsOfScreenDisplayedByType(type));
    }
  }, [type, subscription, loading, error]);

  return (
    <>
      <DrillDownHeader>
        <IconArrowLeft
          data-testid="navigate-button"
          onClick={() =>
            navigation.push(`/${country}/${language}/subscriptions`)
          }
        />
        <div className="subtitle-regular">
          {`${translate('subscriptions.subscriptionDetails', 'Subscription details')}`}
        </div>
      </DrillDownHeader>
      {showSubscriptionWarning()}
      <Content data-testid="subscription-details">
        {loading && (
          <Section
            appearance="outlined"
            content={<Loading />}
          />
        )}
        {error && (
          <ErrorBoundary
            data-testid="subscription-details-with-error"
            analyticsEvent={getModuleDisplayedSubscriptionsDetailsError(
              error.message,
              type
            )}
          />
        )}
        {!loading && !error && subscription?.resourceId && (
          <>
            <PlanDetails
              data-testid="content"
              subscription={subscription}
            />
            <Payment subscription={subscription} />
            <Shipping subscription={subscription} />
            <HaveQuestions subscription={subscription} />
            <MoreOptions subscription={subscription} />
          </>
        )}
      </Content>
    </>
  );
};
