import { SubscriptionType } from '@hpx-core-experiences/react-my-account-commons/dist/index';
import { InlineNotification } from '@veneer/core';
import React, { useEffect } from 'react';
import {
  WarnData,
  NotificationType
} from 'src/components/SubscriptionWarning/types';
import { Notification } from 'src/styles/styles';
import {
  SubscriptionWarningButtonClicked,
  SubscriptionWarningModuleDisplayed,
  publishEvent
} from 'src/utils/analytics';
import { URLS } from 'src/utils/urls';

export const SubscriptionWarning = ({ data }: WarnData) => {
  useEffect(() => {
    publishEvent(
      SubscriptionWarningModuleDisplayed(
        data.subscriptionType as SubscriptionType,
        data.subscriptionState
      )
    );
  }, [data.subscriptionState, data.subscriptionType]);

  return (
    <Notification data-testid="inline-notification">
      <InlineNotification
        action={{
          action: () => {
            window.open(
              data.subscriptionType === SubscriptionType.HP_ALL_IN
                ? URLS.HpAllInOverview
                : URLS.InstantInkOverview,
              '_blank'
            );
            publishEvent(
              SubscriptionWarningButtonClicked(
                data.subscriptionType as SubscriptionType,
                data.subscriptionState
              )
            );
          },
          label: `${data.label}`
        }}
        closeButton={false}
        title={data.title}
        type={data.type as NotificationType}
      />
    </Notification>
  );
};

export default SubscriptionWarning;
